<spy-layout>
    <div class="mp-layout-main-cnt">
        <div class="mp-layout-main-cnt__sidebar">
            <spy-sidebar [collapsed]="isCollapsed" (collapsedChange)="updateCollapseHandler($event)">
                <div class="mp-layout-main-cnt__logo">
                    <div class="mp-layout-main-cnt__logo-inner">
                        <ng-content select="[logo]"></ng-content>
                    </div>
                </div>

                <spy-navigation [items]="navigationConfig" [collapsed]="isCollapsed"></spy-navigation>
            </spy-sidebar>
        </div>

        <div class="mp-layout-main-cnt__main">
            <spy-header class="mp-layout-main-cnt__header">
                <ng-content select="[header]"></ng-content>
            </spy-header>

            <div class="mp-layout-main-cnt__content" spyApplyContexts="spy-bg-gray">
                <span spyCustomElementBoundary>
                    <ng-content></ng-content>
                </span>
            </div>
        </div>
    </div>
</spy-layout>
