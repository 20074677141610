<spy-user-menu>
    <spy-user-menu-item>
        <div class="mp-header-menu__user-info">
            <div class="mp-header-menu__user-info-primary">
                <ng-content select="[info-primary]"></ng-content>
            </div>
            <div class="mp-header-menu__user-info-secondary">
                <ng-content select="[info-secondary]"></ng-content>
            </div>
        </div>
    </spy-user-menu-item>

    <ng-content></ng-content>

    <a class="mp-header-menu__link" *ngFor="let item of navigationConfig" [href]="item.url">
        <spy-user-menu-link [type]="item.type ?? linkType">
            {{ item.title }}
        </spy-user-menu-link>
    </a>
</spy-user-menu>
