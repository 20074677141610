<form
    [method]="method"
    [action]="action"
    [name]="name"
    [spyApplyAttrs]="attrs"
    [spyUnsavedChangesFormMonitor]="withMonitor"
    (submit)="submitHandler()"
>
    <span spyCustomElementBoundary>
        <ng-content></ng-content>
    </span>
</form>
