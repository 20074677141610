<div class="mp-layout-centered__overlay" spyApplyContexts="spy-bg-gray">
    <div class="mp-layout-centered__content">
        <span spyCustomElementBoundary>
            <ng-content></ng-content>
        </span>
    </div>
    <div class="mp-layout-centered__footer">
        <mp-layout-footer>
            <ng-content select="[footer]"></ng-content>
        </mp-layout-footer>
    </div>
</div>
